import dayjs from 'dayjs'
import type { IFilterParams as IListFilterParams } from './useExperienceList'
import { attrRankController } from '~/apis/back/services'
import type {
  AttSpecialTopicConfigGroupDetailResponseDTO,
  ResponseBaseSingleResponseDTOAttSpecialTopicConfigDetailResponseDTO,
} from '~/apis/back/types'
import type { BookItemClickEvent, ViewItemViewEvent } from '~/types/event-tracking'
import { ENUM_EventType, ENUM_PageName } from '~/enums'

interface ISupplierItem {
  id: number
  price?: number
  logo: string
  cashbackDesc: string
  showAll: boolean
  showMore: boolean
  details: Array<AttSpecialTopicConfigGroupDetailResponseDTO>
}

interface IFilterParams {
  travelDate: string
  freeCancellation?: boolean
  instantConfirmation?: boolean
}

export function useExperienceDetail() {
  const route = useRoute()
  const { t } = useI18n()
  const userStore = useUserStore()
  const isMobile = useMobileByScreen()
  const { filterConditions } = useFilterConditionsStore()
  const { locale } = useLocale()
  const gtm = useGtm()

  const filterParams = useState<IFilterParams>('azgo_experience_detail_filter', () => ({
    travelDate: route.query.travelDate as string || dayjs().format('YYYY-MM-DD'),
    freeCancellation: undefined,
    instantConfirmation: undefined,
  }))

  const experienceState = useState('azgo_experience_detail', () => {
    return {
      experienceDetail: {} as ResponseBaseSingleResponseDTOAttSpecialTopicConfigDetailResponseDTO,
      isError: false,
      errorMessage: '',
      defaultExpandLength: 5,
      currentSku: null as AttSpecialTopicConfigGroupDetailResponseDTO | null,
      skuDescVisible: false,
      supplierList: [] as ISupplierItem[],
    }
  })

  const {
    experienceDetail,
    isError,
    errorMessage,
    defaultExpandLength,
    supplierList,
    currentSku,
    skuDescVisible,
  } = toRefs(experienceState.value)

  const experienceInfo = computed(() => experienceDetail.value.data?.data)

  function watchExperienceDetail() {
    const supplierData = experienceDetail.value.data?.data?.specialTopicDetails
    if (!supplierData?.length) {
      supplierList.value = []
      return
    }
    supplierList.value = supplierData
      .sort((a, b) => {
        const aPrice = a.price || Infinity
        const bPrice = b.price || Infinity
        return aPrice - bPrice
      })
      .reduce((acc: ISupplierItem[], item) => {
        const supplierItem = acc.find(accItem => accItem.id === item.supplierId)
        if (supplierItem) {
          // 保证不重复
          if (!supplierItem.details.find(c => c.productCode === item.productCode)) {
            supplierItem.details.push(item)
          }
          return acc
        }
        return [...acc, {
          id: item.supplierId!,
          price: item.price,
          logo: item.supplierImageUrl!,
          cashbackDesc: experienceDetail.value.data?.data?.cashbackDesc || '',
          showAll: false,
          showMore: false,
          details: [item],
        }]
      }, [] as ISupplierItem[])
    supplierList.value.forEach((item) => {
      if (item.details.length > defaultExpandLength.value) {
        item.showAll = false
        item.showMore = true
      }
      else {
        item.showAll = true
        item.showMore = false
      }
    })
  }

  async function getExperienceDetail() {
    const serialNo = route.query.serialNo as string

    const { travelDate, instantConfirmation, freeCancellation } = filterParams.value
    // const { travelDate } = filterParams.value
    const { data, error } = await attrRankController.rankGetSpecialTopicConfigDetailPost({
      specialTopicSerialNo: serialNo,
      travelDate,
      instantConfirmation: instantConfirmation ? 1 : undefined,
      freeCancellation: freeCancellation ? 1 : undefined,
    })
    if (error.value || !data.value || !data.value.data?.success) {
      experienceState.value.isError = true
      experienceState.value.errorMessage = data.value?.data?.errorMessage || '获取数据失败'
      return
    }
    const responseData = data.value?.data.data || {}
    responseData.specialTopicDetails?.forEach((item) => {
      item.supplierImageUrl = item.supplierImageUrl?.replace('http://', 'https://') || ''
    })
    responseData.cover = responseData.cover?.replace('http://', 'https://') || ''
    responseData.images = responseData.images?.map(image => ({
      ...image,
      imageUrl: image.imageUrl?.replace('http://', 'https://') || '',
    }))
    experienceState.value.experienceDetail = data.value
    watchExperienceDetail()
  }

  function trackDetailViewDealEvent(isBigBtn = false, jumpUrl: string, items: ISupplierItem[], supplierRankIndex: number, proRankIndex: number) {
    const skuItem = items[supplierRankIndex] || {}

    const rowData = (isBigBtn ? skuItem?.details?.[0] : skuItem?.details?.[proRankIndex]) || {}

    const selectItemParams: BookItemClickEvent = {
      event: ENUM_EventType.BookItem,
      page_category: ENUM_PageName.Detail,
      city_id: experienceInfo.value?.cityId,
      city: experienceInfo.value?.cityName,
      country_code: experienceInfo.value?.countryId, // 后端 没返回 country_code 例如 86 这样的
      locale: locale.value,
      search_date: dayjs(filterConditions.date).format('YYYY-MM-DD'),
      campaign_id: route.query.campaign_id as string ?? '',
      campaign_name: route.query.campaign_name as string ?? '',
      currency: userStore.userInfo.currency,
      supplier_id: rowData.supplierId,
      supplier_name: '', // 没找到字段
      supplier_list_position: supplierRankIndex + 1,
      product_id: rowData.productCode,
      product_name: rowData.productTitle,
      product_list_position: !isBigBtn ? (proRankIndex ?? 0) + 1 : 0,
      product_price: rowData?.price,
      click_url: jumpUrl,
      button_type: isBigBtn ? 'fromprice_button' : 'product_button',
      experience_id: experienceInfo.value?.specialTopicSerialNo,
      experience_name: experienceInfo.value?.specialTopicName,

    }
    gtm?.trackEvent(selectItemParams)
  }
  async function viewDeal(item: AttSpecialTopicConfigGroupDetailResponseDTO, supplierRankIndex: number, proRankIndex: number, isBigBtn: boolean) {
    const { query } = route
    const { userInfo } = userStore
    const { data } = await attrRankController.rankGetExperiencesJumpUrlPost({
      supplierId: item?.supplierId,
      path: item.productUrl,
      jumpSnapDTO: {
        cid: query.cid as string,
        memberId: userInfo.memberId,
      },
    })
    const jumpUrl = data.value?.data?.data?.jumpUrl
    if (jumpUrl) {
      trackDetailViewDealEvent(isBigBtn, jumpUrl, supplierList.value, supplierRankIndex, proRankIndex)
      //  打开新窗口
      return navigateTo(data.value?.data?.data?.jumpUrl, {
        external: true,
        open: {
          target: isMobile.value ? '_self' : '_blank',
        },
      })
    }
    else {
      showToast(t('key.price.calendar.request.failed'))
    }
  }

  function showMoreHandler(item: ISupplierItem) {
    if (item.details.length > defaultExpandLength.value) {
      item.showAll = false
      item.showMore = true
    }
    else {
      item.showAll = true
      item.showMore = false
    }
  }

  function showAllHandler(item: ISupplierItem) {
    item.showAll = true
    item.showMore = false
  }

  function showFewHandler(item: ISupplierItem) {
    item.showAll = false
    item.showMore = false
  }

  function showLessOrShowAll(item: ISupplierItem) {
    if (item.showAll) {
      showFewHandler(item)
    }
    else {
      showAllHandler(item)
    }
  }

  function getPrice(price?: number) {
    if (!price) {
      return t('key.attraction.detail.price.getPrice')
    }
    return getCurrencySymbol(userStore.userInfo.currency) + toThousands(price)
  }

  function showSkuDesc(item: AttSpecialTopicConfigGroupDetailResponseDTO) {
    currentSku.value = item
    skuDescVisible.value = true
  }

  function initFilter() {
    const filterCookie = useCookie<IListFilterParams>('azgo-experience-filter')
    filterParams.value.freeCancellation = !!filterCookie.value?.freeCancellation
    filterParams.value.instantConfirmation = !!filterCookie.value?.instantConfirmation
  }

  function trackViewItem() {
    const params: ViewItemViewEvent = {
      event: ENUM_EventType.ViewItem,
      page_category: ENUM_PageName.Detail,
      city_id: experienceInfo.value?.cityId,
      city: experienceInfo.value?.cityName,
      locale: locale.value,
      campaign_id: route.query.campaign_id as string ?? '',
      campaign_name: route.query.campaign_name as string ?? '',
      prd_type: 'Experiences',
      supplier_number: supplierList.value?.length ?? 0,
      experience_id: experienceInfo.value?.specialTopicSerialNo,
      experience_name: experienceInfo.value?.specialTopicName,
    }
    gtm?.trackEvent(params)
  }
  return {
    experienceState,
    experienceDetail,
    isError,
    errorMessage,
    experienceInfo,
    supplierList,
    defaultExpandLength,
    currentSku,
    skuDescVisible,
    filterParams,
    initFilter,
    getExperienceDetail,
    viewDeal,
    showMoreHandler,
    showLessOrShowAll,
    getPrice,
    showSkuDesc,
    trackViewItem,
  }
}
